<template>
  <div>
    <CCard>
      <CCardHeader class="d-flex flex-wrap align-items-center">
        <SMessageWallet :data="wallet" />
        <TSpinner :loading="fetching" />
        <div class="ml-auto">
          <TButtonRefresh
            @click="
              $store.dispatch('accounting.wallets_users.detail.fetch', id)
            "
          />
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol col="8">
            <CRow>
              <CCol md="4">
                <CCallout color="info">
                  <TMessage content="User" bold color="info" uppercase />
                  <TMessage
                    :content="wallet.object ? wallet.object.name : '???'"
                    class="h5"
                    noTranslate
                  />
                </CCallout>
              </CCol>
              <CCol md="4">
                <CCallout color="primary">
                  <TMessage content="Balance" bold color="primary" uppercase />
                  <TMessageMoney
                    :amount="wallet.balance"
                    class="h5"
                    :currency="wallet.currency_id"
                  />
                </CCallout>
              </CCol>
              <CCol md="4">
                <CCallout color="primary">
                  <TMessage
                    content="Available payment"
                    bold
                    color="primary"
                    uppercase
                  />
                  <TMessageMoney
                    :amount="wallet.amount_available_for_payment"
                    class="h5"
                    :currency="wallet.currency_id"
                  />
                </CCallout>
              </CCol>
            </CRow>
          </CCol>
          <CCol md="4">
            <CCallout color="primary">
              <div class="d-flex">
                <TMessage
                  content="Credit card"
                  bold
                  color="primary"
                  uppercase
                />
                <CLink
                  class="ml-2"
                  @click="
                    $router.push({
                      path: `/accounting/account/credit?walletable_id=${wallet.walletable_id}`,
                    })
                  "
                  :title="$t('Order credit')"
                >
                  <CIcon name="cil-arrow-right" />
                </CLink>
              </div>
              <TTableAsForm
                :fields="creditFields"
                :data="wallet"
                :splitLeft="5"
                :addRowClasses="['p-0']"
              >
                <template #limit>
                  <TMessageMoney
                    :amount="wallet.credit_limit"
                    editable
                    :currency="wallet.currency_id"
                    @change="updateWallet('credit_limit', $event)"
                  />
                </template>
                <template #outstanding>
                  <TMessageMoney
                    :amount="wallet.total_outstanding_credit_order_amount"
                    :currency="wallet.currency_id"
                  />
                </template>
                <template #remaining>
                  <TMessageMoney
                    :amount="wallet.total_remaining_credit_order_amount"
                    :currency="wallet.currency_id"
                  />
                </template>
              </TTableAsForm>
            </CCallout>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <TableTransactions
      v-if="wallet.id"
      store="accounting.transactions"
      class="mb-4"
      :default_filter="default_filter"
      :key="`table-transactions-${wallet.id}`"
    />
  </div>
</template>

<script>
import TableTransactions from "../../../components/TableTransactions.vue";

export default {
  components: { TableTransactions },
  data() {
    return {
      creditFields: [
        { key: "limit", label: "Credit limit" },
        { key: "outstanding", label: "In use" },
        { key: "remaining", label: "Remaining" },
      ],
    };
  },
  created() {
    this.$store.dispatch("accounting.wallets_users.detail.fetch", this.id);
  },
  computed: {
    wallet() {
      return this.$store.getters["accounting.wallets_users.detail"];
    },
    fetching() {
      return this.$store.getters["accounting.wallets_users.detail.fetching"];
    },
    id() {
      return this.$route.params.id;
    },
    default_filter() {
      return {
        "filter[transactionable_type]": this.wallet.walletable_type,
        "filter[wallet_id]": this.wallet.id,
      };
    },
  },
  methods: {
    updateWallet(field, value) {
      return this.$store.dispatch("accounting.wallets_users.detail.update", {
        [field]: value,
      });
    },
  },
};
</script>
